export default [{
    prop: 'id',
    label: 'ID',
    type: 'text'
  },
  {
    prop: 'name',
    label: 'Tên',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'status_format',
    label: 'Trạng thái',
    minWidth: 120,
    type: 'text'
  }
]