<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="groups" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import groupSchemas from './group-schemas'
  import dtHelper from 'src/helpers/datatable'
  import { mapActions } from 'vuex'

  export default{
    components: {
      MyTable,
      MyFilter
    },

    computed: {
      groups() {
        let rows = this.$store.state.groups;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(groupSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(groupSchemas)
      }
    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchGroups');
      this.$store.dispatch('setPageTitle', 'Quản lý Bảng');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.create
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      edit(index, row){
        this.$router.push('/groups/' + row.id);
      },
      create() {
        this.$router.push('/groups/create');
      },
      remove(index, row) {
        MessageBox.confirm('Xóa', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeGroup', row.id).then(res => {
            if (res.success) {
              Message({
                message: 'Đã xóa',
                type: 'success'
              });
              this.$store.dispatch('fetchGroups');
            } else {
              if (res.errors) {
                Message({
                  message: res.errors,
                  type: 'danger'
                });
              } else {
                Message({
                  message: 'Có lỗi xảy ra, xin vui lòng thử lại',
                  type: 'danger'
                });
              }
            }
          });
        }, error => {

        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
